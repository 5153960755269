import { Component, OnInit } from '@angular/core';
import { IMAGE_URL } from 'app/constants';

@Component({
  selector: 'app-supportpage',
  templateUrl: './supportpage.component.html',
  styleUrls: ['./supportpage.component.scss']
})
export class SupportpageComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;
  faqItems = [
    { question: 'Placing an Order', answer: `Go to <a href="https://ishoppurium.com/">ishoppurium.com</a> and login to your account.  If you cannot remember your password use the “Reset Password” function to have a password link sent to you.` },

    {
      question: 'Cancel an Existing Order', answer: `If you need to modify your order, sign in at <a href="https://ishoppurium.com/">ishoppurium.com</a> and go to the "My Account" tab to display order history. <br><br>The EDIT option becomes available approximately 20 minutes after completing your order. Act quickly as it goes away once the order begins processing.
    ` },

    {
      question: 'Track Your Order', answer: `Log into <a href="https://ishoppurium.com/">ishoppurium.com</a>. The “My Account” tab displays order history, including tracking number. Your tracking number will also be emailed to you once your order has been shipped.<br><br>FedEx Delivery Manager App<br><br>Most of Purium's orders are sent via FedEx. Download their Delivery Manager App to use these great features:<br><br>
    Get tracking updates (via text message)<br>
    Request package be held at “hold facility”<br>
    Request a special delivery time (for a fee)<br>
    Add delivery notes<br>
    Set vacation time for all deliveries<br>
    Request to hold your package for 14 days (this is the longest consecutive time period; must wait 6 days to do another hold)<br>
    Use the FedEx “Virtual Assistant” and Customer Chat` },

    {
      question: 'Return an Item or Order', answer: `Clearly mark RMA and your order # on the outside of the box and return all contents of your package within 60 days to the address below. Please include any specific details inside package.<br><br>Refunds are processed only after returns are received. Funds are typically returned approximately 2-3 weeks after receipt of a returned package.<br><br>Returns will be accepted at will call however they WILL NOT be processed on the spot.<br><br>US Returns<br>
    2610 Homestead Place<br>
    Rancho Dominguez, CA 90220
    <br><br>
    Canada Returns<br>
    130 - 1959 152nd Street<br>
    STE 512<br>
    Surrey, BC V4A 0C4
    ` },

    { question: 'Creating, Editing, or Canceling Monthly Subscriptions', answer: `Go to <a href="https://ishoppurium.com/">ishoppurium.com</a> and login to your account. Click on the Monthly Subscription tab to modify.` },

    {
      question: 'Enrolling or Upgrading to Brand Partner', answer: `To Enroll as a Brand Partner - If you are new to Purium (not in our system), please enroll at puriumenrollment.com<br><br>

    To Upgrade from a Customer to a Brand Partner - Login at <a href="https://ishoppurium.com/">ishoppurium.com</a> and choose the Upgrade to Brand Partner button.<br>` },

    {
      question: 'Redeem Rewards Points', answer: `If you have Points to redeem, they will appear as a Payment option as you checkout.<br><br>

    Rewards points can be redeemed in 1000 point increments.<br><br>
    
    If there is an issue, please fill out the Web Form:
  <a href="https://bit.ly/PuriumCustomerCare">https://bit.ly/PuriumCustomerCare</a>, choose Issue “5 -Commissions, Promos & Business Questions” and include as much info as possible in “Issue Details” for agents to resolve quickly.` },

    {
      question: 'Will Call Details & Instructions', answer: `Ready for pickup anytime Monday - Friday 8:00 am - 5:30 pm!.<br><br>

    Note: Orders must be picked up within 3 Business Days of purchase date. If orders are not picked up within 3 business days of the purchase date, the purchase will be canceled, refunded, and re-shelved. <br><br>
    
    Will Call Hours<br>
    Monday - Friday<br>
    8am - 5:30pm PT <br><br>
    
    Will Call Address:<br>
    2610 Homestead Place<br>
    Rancho Dominguez, CA 90220<br><br>
    
    Park in visitor parking, ring the doorbell near the Will Call sign & show your ID.<br><br>
    
    
    Customers who purchased the order must show ID and be physically present to pick-up and, unless other arrangements have been previously made through customer care.<br><br>
    
     <a href="https://bit.ly/PuriumCustomerCare">https://bit.ly/PuriumCustomerCare</a>.` },

    {
      question: 'DREAMS Phone App Support', answer: `Telephone: (800) 544-7044<br>
    Email: support@hussle.tech
    ` }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
