import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class uploadImageVedioService {
    private cloudName = 'dlx3ndams';
    private uploadPreset = 'my_preset_review';

    constructor(private http: HttpClient) { }

    uploadFile(file: File,type: string) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', this.uploadPreset);
        if(type == 'picture') {
            const cloudinaryUrlImg = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
            return this.http.post(cloudinaryUrlImg, formData);
        }else if(type == 'video') {
            const cloudinaryUrlVedio = `https://api.cloudinary.com/v1_1/${this.cloudName}/video/upload`;
            return this.http.post(cloudinaryUrlVedio, formData);
        }
    }
}