import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as _ from 'lodash';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from 'app/shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { CommonService } from 'app/shared/common.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { CalculateRetailInitialOrderService } from 'app/shared/calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from 'app/shared/CalculateRetailSmartOrder.service';
import { Subject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { MatDrawerMode } from '@angular/material/sidenav';
import { VerifyAddressComponent } from 'app/shared/verify-address/verify-address.component';
import * as moment from 'moment';
import { OrderAlertMessageComponent } from './OrderAlertMessage/OrderAlertMessage.component';
import { IMAGE_URL } from 'app/constants';

@Component({
  selector: 'app-review-page',
  templateUrl: './ReviewPage.component.html',
  styleUrls: ['./ReviewPage.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewPageComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;
  backofficeUrl: any;
  cardNum: any;
  zoom = 14;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 50,
    minZoom: 4,
  };
  markers: any[] = [];
  latitude: any;
  longitude: any;
  userData: any;
  AuthorizeCheckBox: FormControl =  new FormControl('',Validators.requiredTrue);
  @Input() routPath: any;
  dialogRef: any;
  currentRouterPath: any;
  finalShippingMethod: any;
  siteType: any;
  customerDetail: any = '';
  priceType: any;
  packSectionTitle: any;
  public isLogout: boolean = false;
  public selectedDiscount: any;
  public DiscountValue: any;
  isSelected: boolean = false;
  public totalRemainingBalance: any;
  private _unsubscribeAll: Subject<any>;
  ProductCode: any;
  SmartOrderDate: any;
  LoggedInUser: any = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
  SubscriptionProcessType: any;
  processTypeDisplay:any;
  processTypeDisplayAutoOrder:any
  mode = new FormControl('over' as MatDrawerMode);
  subscriptionPanelOpenState: any = false;
  ultLifestyleFlow:any;
  smartOrderDateForLifestyle:any;
  AutoOrderDetails:any;
  isAuthorize:boolean = false;
  creditCardNum: any;
  paymentDetails:any;
  CustomAutoOrderList: any[];
  constructor(
    public _enrollSession: EnrollSessionService,
    private _toastr: ToastrService,
    private _matDialog: MatDialog,
    public router: Router,
    private cookieService: CookieService,
    public _restService: RestService,
    public _auth: AuthService,
    public _calculateRetailOrderService: CalculateRetailOrderService,
    public _calculateAutoOrder: CalculateAutoOrderService,
    public _sharedMethodService: SharedMethodService,
    private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
    public _commonService: CommonService,
    private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
  ) {
    this.currentRouterPath = this.router.url.split('?')[0];
    this.siteType = sessionStorage.getItem("siteType");
    this.creditCardNum = localStorage.getItem('PaymentMethodDifferent');
    this.paymentDetails = JSON.parse(localStorage.getItem('billingInfo')) || {};
    this.ultLifestyleFlow = sessionStorage.getItem("onUltLifestyleFlow");
    if(this.ultLifestyleFlow === null || this.ultLifestyleFlow === undefined || this.ultLifestyleFlow === ''){
      if(this.siteType === 'ultlifestyle'){
        sessionStorage.setItem('onUltLifestyleFlow', 'true');
      }
      else{
        sessionStorage.setItem('onUltLifestyleFlow', 'false');
      }
    }
    this.AutoOrderDetails = JSON.parse(localStorage.getItem("AutoOrderDetails"));
    this.smartOrderDateForLifestyle = moment().add(1, 'months').format('MM/DD/YYYY');
  }

  ngOnInit(): void {
    this._enrollSession.editTransformationPack = '';
    this.ultLifestyleFlow = sessionStorage.getItem("onUltLifestyleFlow");
    if(this._auth.isLoggedIn()){
      var num =  (this.creditCardNum === null || this.creditCardNum === 'true') ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber || this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField : this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField;
    }
    else{
      var num =  this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber;
    }
    this.cardNum = num?.toString().slice(-4);
    this.userData = JSON.parse(this._auth.getUserData());
    this.processTypeDisplay = localStorage.getItem("SubscriptionProcessType") == 'AutoOrderProcessTy1' ? 'Always Ship' : 'Backup Order';
    this.processTypeDisplayAutoOrder = this.AutoOrderDetails?.ProcessType == 0 ? 'Always Ship' : 'Backup Order';
  }


  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--
  }

  ClickToBack() {
    this.router.navigate(['/Checkout'])
  }

  //Summary-Cart

  onSubmitApplication() {
    try {
      if (this._auth.isLoggedIn()) {
        this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
        if (this.LoggedInUser.CustomerID && this.LoggedInUser.CustomerID !== 0) {
          this.checkAutoshipDetails().then((autoOrderID) => {
            this.getSubmit();
          }).catch((ex) => { this.getSubmit(); });
        }
        else {
          alert("Your session has been expired. Please logout and login again.");
        }
      }
      else {
        this.getSubmit();
      }
    }
    catch (ex) {
      this.getSubmit();
    }
  }
  
  onClickChange(isCheckout?: boolean) {
    if (this._enrollSession.data.RetailOrderSummary?.accountInfoData?.Password !== "" && !this._auth.isLoggedIn()) {
      this._commonService.validatePassword(this._enrollSession.data.RetailOrderSummary?.accountInfoData?.Password);
      if (this._enrollSession.data.RetailOrderSummary?.accountInfoData?.Password !== this._enrollSession.data.RetailOrderSummary?.accountInfoData?.ConfirmPassword) {
        this._toastr.error('Please verify your Password', 'error');
        return;
      }
      if (this._commonService.hasLower == false || this._commonService.hasLength == false || this._commonService.hasUpper == false || this._commonService.hasNum == false || this._commonService.hasSpecial == false) {
        this._toastr.error('Please check your Password', 'error');
        return;
      }
    }
    if(this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary?.SmartOrders && this._enrollSession.data.RetailOrderSummary?.SmartOrders?.length > 0){
      if(this.isAuthorize === false && this.AuthorizeCheckBox?.invalid){
        this.validateAllFormFields(this.AuthorizeCheckBox);
        this._toastr.warning('Please select the Authorize Box');
        return;
      }
    }
    if (this.currentRouterPath.includes("Review-Page")) {
      this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
      if (this._auth.isLoggedIn()) {
        this._sharedMethodService.getPointAccount(this.LoggedInUser.CustomerID).then((balance) => {
          if (parseFloat(this._calculateRetailOrderService.DiscountValue) > parseFloat(balance)) {
            this._toastr.error("Insufficient Point Balance");
            return;
          }
          this.onSubmitApplication();
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      } else {
        this.onSubmitApplication();
      }
    }
  }
  removedOrder(index, Quantity, parentId, orderType) {
    if (orderType == 'todayOrder') {
      this.removeInitOrder({ index: index, Quantity: Quantity, parentId: parentId });
    }
    if (orderType == 'smartOrder') {
      this.removeSmartOrder({ index: index, Quantity: Quantity, parentId: parentId });
    }
    if (orderType == 'transformation') {
      this.EditTransformPack();
    }
    if ((this._enrollSession.data.RetailOrderSummary == undefined) || this._enrollSession.data.RetailOrderSummary.InitialOrders == undefined || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length == 0) && (!this.siteType || this.siteType == null || this.siteType == '')) {
      this._toastr.warning("Please select an item", '');
      this.router.navigate(['/AllProducts']);
    }
  }
  // ****removeSmartOrder****/
  removeSmartOrder(Value: any): any {
    this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
    for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.SmartOrders.length; arrIndex++) {
      if ((!this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId ||
        this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId === Value.parentId) &&
        this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ItemCode === Value.parentId) {
        this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(arrIndex, 1);
      }
    }

    this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
      return item.ParentId !== Value.parentId;
    });
    const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
      return item.ParentId !== undefined;
    });
    this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');

    this._enrollSession.data.SmartOrdersSubTotal = 0;
    this._enrollSession.data.SmartOrderBVTotal = 0;
    _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
      this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
      this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);
    });
    if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
      this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
    } else {
      this.finalShippingMethod = '';
    }
    this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
  }
  // ****removeInitOrder****/
  removeInitOrder(Value: any): any {
    this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
    for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.InitialOrders.length; arrIndex++) {
      if ((!this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId ||
        this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId === Value.parentId) &&
        this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ItemCode === Value.parentId) {
        this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(arrIndex, 1);
      }
    }
    this._enrollSession.data.InitialOrdersSubTotal = 0;
    this._enrollSession.data.InitialOrdersQVTotal = 0;
    _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
      this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
      this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);
    });
    this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
      return item.ParentId !== Value.parentId;
    });
    this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
      return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
    });
    const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
      return item.ParentId && item.ParentId !== undefined;
    });

    this._enrollSession.data.InitialOrdersSubItems = '';
    this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
    if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
      this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
    } else {
      this.finalShippingMethod = '';
    }
    this._calculateRetailInitialOrder.calculate(this.finalShippingMethod).then(() => {
      this._calculateRetailOrderService.RedeemDiscount();
    });
  }

  EditTransformPack() {
    this._enrollSession.editTransformationPack = "true";
    this.router.navigate(['/AllProducts']);
  }
  closeMobileSummaryCart() {
    this._enrollSession.SummaryCartMobOnly = !this._enrollSession.SummaryCartMobOnly;
  }
  
  suggestedAddress() {
    let reqAddress = {
      address1: this._enrollSession.data.RetailOrderSummary.shippingFormData.address1,
      city: this._enrollSession.data.RetailOrderSummary.shippingFormData.City,
      state: this._enrollSession.data.RetailOrderSummary.shippingFormData.State,
      countryCode: this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,
      zip: this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode
    }


    this._restService.GetVerifedAddressResponseV1(reqAddress).subscribe((response: any) => {
      if (!response.Data.isVerified) {
        this.dialogRef = this._matDialog.open(VerifyAddressComponent, {
          width: '550px',
          maxWidth: '550px',
          height: '456px',
          panelClass: '',
          data: {
            suggestedAddress: {},
            userAddress: reqAddress,
            InvalidAddress: true
          },
          disableClose: true
        });
        this.dialogRef.afterClosed()
          .subscribe(response => {
            if (response.continue) {
              console.log("response", response.continue);
              this.router.navigate(['/', this.routPath]);
            }
            else { return; }
          });
      }
      let resAddress = {
        address1: (response.Data.response.addressField)?.trim(),
        city: response.Data.response.cityField,
        state: response.Data.response.stateField,
        countryCode: response.Data.response.countryField,
        zip: response.Data.response.zipField
      }
      let isSuggestedAddress = !this._commonService.checkAddress(reqAddress, resAddress)
      if (isSuggestedAddress) {
        this.dialogRef = this._matDialog.open(VerifyAddressComponent, {
          width: '550px',
          maxWidth: '550px',
          height: '456px',
          panelClass: '',
          data: {
            suggestedAddress: resAddress,
            userAddress: reqAddress,
            InValidAddress: false
          },
          disableClose: true
        });
        this.dialogRef.afterClosed()
          .subscribe(response => {
            if (response) {
            }
            this.router.navigate(['/', this.routPath]);
          });

      }
      else {
        this.router.navigate(['/', this.routPath]);
      }
    })
  }

  getSubmit(): void {
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    this.SmartOrderDate = this._enrollSession.data.smartOrderDate;
    this.SubscriptionProcessType = localStorage.getItem("SubscriptionProcessType")=='AutoOrderProcessTy1'?0:1;
    this._sharedMethodService.submissionInProgress = true;
    let isSavedCard = this._auth.isLoggedIn() ? (this._enrollSession.data?.RetailOrderSummary?.paymentTypeData?.primaryPaymentCard == 'true' ? true : false) : (this._enrollSession.data.usePrimaryCard ? this._enrollSession.data.usePrimaryCard : '')
    let request: any;
    request =
    {
      'CustomerId': this.LoggedInUser && Object.keys(this.LoggedInUser).length > 0 ? this.LoggedInUser.CustomerID : this._enrollSession.data.customertype === 22 ? 0
        : (this._enrollSession.data.IsUpGrade ? this._enrollSession.data.IsUpGrade : this._enrollSession.data.CustomerId ?
          this._enrollSession.data.CustomerId : ((this._enrollSession.data.customertype === 18 || this._enrollSession.data.customertype === 11) && this._enrollSession.data.CustomerID ? this._enrollSession.data.CustomerID : 0)),
        'AcceptTerms': this._enrollSession.data.RetailEnroll.AgreeTerms ? this._enrollSession.data.RetailEnroll.AgreeTerms : true,
        'Field5': '',
        'IsProfessional':  false,
        'IsNonprofit': false,
         'ApplicantAddress': {
            'Address1': this._enrollSession.data.RetailOrderSummary.customerInfoData.address1 ? this._enrollSession.data.RetailOrderSummary.customerInfoData.address1 : '',
            'Address2': this._enrollSession.data.RetailOrderSummary.customerInfoData.address2 ? this._enrollSession.data.RetailOrderSummary.customerInfoData.address2 : '',
            'Address3': '',
            'City': this._enrollSession.data.RetailOrderSummary.customerInfoData.City ? this._enrollSession.data.RetailOrderSummary.customerInfoData.City : '',
            'CountryCode': this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode ? this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode : '',
            'State': this._enrollSession.data.RetailOrderSummary.customerInfoData.State ? this._enrollSession.data.RetailOrderSummary.customerInfoData.State : '',
            'Zip': this._enrollSession.data.RetailOrderSummary.customerInfoData.ZipCode ? this._enrollSession.data.RetailOrderSummary.customerInfoData.ZipCode : ''
        },
        'BirthDate': '',
        'Company': this._enrollSession.data.RetailOrderSummary.customerInfoData.CompanyName || '',
        'CustomerType': this.LoggedInUser.CustomerType? this.LoggedInUser.CustomerType: 22,
        'CustomerWebAlias': this._enrollSession.data.ReferredByGiftCard ? this._enrollSession.data.ReferredByGiftCard : 0,
        'Email': this._enrollSession.data.RetailOrderSummary.customerInfoData.Email ? this._enrollSession.data.RetailOrderSummary.customerInfoData.Email : '',
        'EnrollerWebAlias': this._enrollSession.data.ReferredBy ? this._enrollSession.data.ReferredBy : '',
        'FirstName':!this._auth.isLoggedIn() ? this._enrollSession.data.RetailOrderSummary.customerInfoData.FirstName : null,
        'LastName': !this._auth.isLoggedIn() ? this._enrollSession.data.RetailOrderSummary.customerInfoData.LastName : null,
        'Gender': '',
        'BusinessName': this._enrollSession.data.RetailEnroll.BusinessName ? this._enrollSession.data.RetailEnroll.BusinessName : this._enrollSession.data.RetailOrderSummary.customerInfoData.CompanyName || '',
        'TaxId': this._enrollSession.data.RetailEnroll.EINNumber ? this._enrollSession.data.RetailEnroll.EINNumber :
            this._enrollSession.data.RetailEnroll.CommissionSSN,
        'TaxIDType': 'SSN',
        'LanguageCode': 'en',
        'OrderRequest': {
            'CurrencyCode': 'USD',
            'Details': this._enrollSession.data?.OrderProductDetalis ? this._enrollSession.data.OrderProductDetalis : this._enrollSession.data.RetailOrderSummary.InitialOrders,
            'ShipMethodID': this._enrollSession.data.Order_shipMethodIDField,
            'IsShipAmntDiscount': this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal >= 250
        },
        'PartyID': '',
        'Password': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? '' : this._enrollSession.data.RetailOrderSummary.accountInfoData.Password,
        'PriceType': this._enrollSession.data.priceType,
        'PointTransaction': {
            'PointAccountID': 2,
            'CustomerID': this.LoggedInUser.CustomerID,
            'Amount': parseFloat(this._calculateRetailOrderService.DiscountValue),
            'Reference': "",
            'TransactionType': 1,
            'CustomerKey': "",
        },
        'Payment': {
            'Amount': this._calculateRetailOrderService.totalRemainingBalance?this._calculateRetailOrderService.totalRemainingBalance.toFixed(2) : (this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total ? this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total : 0),
            'BillingAddress': {
                'Address1': this._enrollSession.data.RetailOrderSummary.billingAddressData.address1 ? this._enrollSession.data.RetailOrderSummary.billingAddressData.address1 : '',
                'Address2': this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 ? this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 : '',
                'Address3': ' ',
                'City': this._enrollSession.data.RetailOrderSummary.billingAddressData.City ? this._enrollSession.data.RetailOrderSummary.billingAddressData.City : '',
                'CountryCode': this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode ? this._enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode : '',
                'State': this._enrollSession.data.RetailOrderSummary.billingAddressData.State ? this._enrollSession.data.RetailOrderSummary.billingAddressData.State : '',
                'Zip': this._enrollSession.data.RetailOrderSummary.billingAddressData.ZipCode ? this._enrollSession.data.RetailOrderSummary.billingAddressData.ZipCode : '',
                'FirstName': this._enrollSession.data.RetailOrderSummary.billingAddressData.firstName ? this._enrollSession.data.RetailOrderSummary.billingAddressData.firstName : '',
                'LastName': this._enrollSession.data.RetailOrderSummary.billingAddressData.lastName ? this._enrollSession.data.RetailOrderSummary.billingAddressData.lastName : '',
                'BusinessName': this._enrollSession.data.RetailOrderSummary.customerInfoData.CompanyName || ''
            },
            'CardNumber': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber : ''),
            'CardType': this._enrollSession.data.RetailEnroll.CardType ? this._enrollSession.data.RetailEnroll.CardType : '',
            'CurrencyCode': this._enrollSession.data.RetailEnroll.CurrencyCode ? this._enrollSession.data.RetailEnroll.CurrencyCode : '',
            'ExpirationMonth': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.expMonth : ''),
            'ExpirationYear': (this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.expYear : ''),
            'NameOnCard': this._enrollSession.data.RetailOrderSummary.paymentTypeData.nameOnCard ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.nameOnCard : '',
            'SavePayment': true,
            'CvcCode': this._enrollSession.data.RetailOrderSummary.paymentTypeData.securityCode ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.securityCode : '',
            'SelectedCc': 1
        },

        'Payment2': {
            'Amount': 0,
            'BillingAddress': {
                'Address1': '',
                'Address2': '',
                'Address3': '',
                'City': '',
                'CountryCode': '',
                'State': '',
                'Zip': ''
            },
            'CardNumber': '',
            'CardType': '',
            'CurrencyCode': '',
            'ExpirationMonth': 0,
            'ExpirationYear': 0,
            'NameOnCard': '',
            'SavedPaymentMethodID': '',
            'SavePayment': '',
            'CvcCode': '',
            'SelectedCc': 1
        },
        'Phone1': this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone,
        'Phone2': '',
        'MobilePhone': this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone,
        'FaxNumber': '',
        'sendSMS':this._enrollSession.data.RetailOrderSummary.customerInfoData.smsOptIn || false,
        'sendEmails': this._enrollSession.data.RetailOrderSummary.customerInfoData.emailOptIn || false,
        'ShippingAddress': {
            'Address1': this._enrollSession.data.RetailOrderSummary.shippingFormData.address1,
            'City': this._enrollSession.data.RetailOrderSummary.shippingFormData.City,
            'CountryCode': this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,
            'State': this._enrollSession.data.RetailOrderSummary.shippingFormData.State,
            'Zip': this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode,
            'FirstName': this._enrollSession.data.RetailOrderSummary.shippingFormData.firstName,
            'LastName': this._enrollSession.data.RetailOrderSummary.shippingFormData.lastName,
            'BusinessName': this._enrollSession.data.RetailOrderSummary.shippingFormData.CompanyName || '',
            'Address2': this._enrollSession.data.RetailOrderSummary.shippingFormData.address2,
            'Address3': ''
        },

        'SiteId': '',
        'SponsorId': this._enrollSession.data.RetailEnroll.SponsorId ? this._enrollSession.data.RetailEnroll.SponsorId : '',
        'EnrollerId': this._enrollSession.data.RetailEnroll.EnrollerId ? this._enrollSession.data.RetailEnroll.EnrollerId : '',
        'StoreID': '',
        'TextNumber': '',
        'Username': this._enrollSession.data.RetailOrderSummary.customerInfoData.Email ? this._enrollSession.data.RetailOrderSummary.customerInfoData.Email : '',
        'WebPageItemID': '',
        'WebPageURL': '',
        'DirectDeposit': {
            'NameOnAccount': this._enrollSession.data.RetailEnroll.CommissionNameOnAccount ? this._enrollSession.data.RetailEnroll.CommissionNameOnAccount : '',
            'BankAccountNumber': this._enrollSession.data.RetailEnroll.CommissionAccNo ? this._enrollSession.data.RetailEnroll.CommissionAccNo : '',
            'BankRoutingNumber': this._enrollSession.data.RetailEnroll.CommissionRoutingNo ? this._enrollSession.data.RetailEnroll.CommissionRoutingNo : '',
            'DepositAccountType': '',
            'BankName': this._enrollSession.data.RetailEnroll.CommissionBankName ? this._enrollSession.data.RetailEnroll.CommissionBankName : '',
            'BankAddress': '',
            'BankCity': '',
            'BankState': '',
            'BankZip': '',
            'BankCountry': '',
            'Iban': '',
            'SwiftCode': ''
        },
        'BillingAddressPreference': this._enrollSession.data.RetailEnroll.BillAddress === 'yes' ? 0 : 1,
        'CommissionPayableType': '',
        'CommissionPayableTo': this._enrollSession.data.RetailEnroll.payAbleTo ? this._enrollSession.data.RetailEnroll.payAbleTo : '',
        'HasRequestedOptIn': (this._enrollSession.data.RetailEnroll.ReceiveText || this._enrollSession.data.RetailEnroll.ReceiveEmail) ? true : false,
        'IsNotBackOffice': this._enrollSession.data.customertype === 22 && this._enrollSession.data.checkoutwithlimited ? true : false,
        'GiftCardCode': '',
        'UsePrimaryCard': isSavedCard && localStorage.getItem('SavedCardType') == 'primary', //this._auth.isLoggedIn()? (this.paymentTypeForm.get('primaryPaymentCard').value == 'true'? true: false) : (this._enrollSession.data.usePrimaryCard ? this._enrollSession.data.usePrimaryCard : ''),
        'UseSecondaryCard': isSavedCard && localStorage.getItem('SavedCardType') == 'secondary',
        'SMSOptIn': this._enrollSession.data.RetailOrderSummary.customerInfoData.smsOptIn || false,
        'EmailOptIn': this._enrollSession.data.RetailOrderSummary.customerInfoData.emailOptIn || false,
        "AutoOrderProcessType": this.SubscriptionProcessType,
        "IsNewAddress":  this.LoggedInUser.CustomerID ? true : false,
        "IsLoggedInCustomer": this._auth.isLoggedIn()? true : false,
      	"IsExisting_11_18_Customer": this._enrollSession.data.RetailEnroll.isWebAliasUpdated ? true : false,
        "IsTokenEx": true
    };
    if (this.siteType !='ultlifestyle' && this._enrollSession.data.SmartOrderProductDetalis && this._enrollSession.data.SmartOrderProductDetalis.length > 0 && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length>0) {
      let SmartOrderShippingMethod = this._enrollSession.data?.ShippingMethods?.find(ShippingMethod =>
        (ShippingMethod.ShipMethodID == 87 || ShippingMethod.ShipMethodID == 97 || ShippingMethod.ShipMethodID == 101 || ShippingMethod.ShipMethodID == 129)
      ).ShipMethodID;
      request.AutoOrderRequest =
        {
            'AutoOrderCartID': '',
            'AutoShipStartDate': this.SmartOrderDate,
            'AutoshipType': 0,
            'BeginMonth': 0,
            'Details': this._enrollSession.data.SmartOrderProductDetalis,
            'ShipMethodID': SmartOrderShippingMethod ? (SmartOrderShippingMethod === 129 ? 97 : SmartOrderShippingMethod) : this._enrollSession.data.Order_shipMethodIDField,
            "AutoshipId": this._auth.isLoggedIn() && localStorage.getItem('isExistingAutoship') == 'true' ? localStorage.getItem('AutoOrderID')   : "",
        };
    }
    
    if(this.siteType == 'ultlifestyle')
    {
        this._restService.SubmitApplication(request).subscribe((result: any) => {
            this._sharedMethodService.submissionInProgress = false;
            if (result) { 
                this._enrollSession.submitApplicationData = result;
                sessionStorage.setItem('submitApplicationData', JSON.stringify(this._enrollSession.submitApplicationData));
                this._enrollSession.data.RetailEnroll.orderDetails = result;
                if (result.OrderID > 0) {
                    let itemDetail = [];
                    let itemDetail2 = [];
                    let itemDetail4 = [];
                    // JS Tracking
                    this._commonService.placeOrderTracking("", result, result.CustomerID);
                    this._commonService.createCustomerTracking(result.CustomerID);
                    this._enrollSession.data.RetailOrderSummary.SmartOrders.forEach(item => {
                        if (item.type=="2Month") {
                            itemDetail2.push({ ItemCode: item.ItemCode, Quantity: item.Quantity, ParentItemCode: (item.ParentId && item.ParentId != item.ItemCode) ? item.ParentId : '' });
                        }
                        else if (item.type=="4Month") {
                            itemDetail4.push({ ItemCode: item.ItemCode, Quantity: item.Quantity, ParentItemCode: (item.ParentId && item.ParentId != item.ItemCode) ? item.ParentId : '' });
                        }
                        else 
                        {
                            itemDetail.push({ ItemCode: item.ItemCode, Quantity: item.Quantity, ParentItemCode: (item.ParentId && item.ParentId != item.ItemCode) ? item.ParentId : '' });
                        }
                    });
                    let autoshipCustomerId = result.CustomerID > 0 ? result.CustomerID : (this._auth.isLoggedIn() ? this.LoggedInUser.CustomerID : (this._enrollSession.data.IsUpGrade ?this._enrollSession.data.IsUpGrade : this._enrollSession.data.CustomerId));
                    this._commonService.CreateAutoOrder(itemDetail2, "2Month", autoshipCustomerId);
                    this._commonService.CreateAutoOrder(itemDetail4, "4Month", autoshipCustomerId);
                } else {
                    this._toastr.error(result.Result.errorsField, 'Error');
                    if (result.Result.errorsField[0] && result.Result.errorsField[0].includes("The operation has timed out")) {
                        this.dialogRef = this._matDialog.open(OrderAlertMessageComponent, {
                            width: '550px',
                            maxWidth: '550px',
                            height: '456px',
                            panelClass: '',
                            data: {

                            },
                            disableClose: true
                        });
                        this.dialogRef.afterClosed()
                            .subscribe(response => {
                                if (response) {
                                }
                                this.router.navigate(['/', this.routPath]);
                            });
                    }

                }
            } else {
                this._toastr.error('Something went wrong please try again', 'Error');
            }
        }, (err) => {
            this._sharedMethodService.submissionInProgress = false;
            this._toastr.error('Something went wrong please try again', 'Error');
        }, () => {

        });
    }
    else{
    this._restService.SubmitApplication(request).subscribe((result: any) => {
        this._sharedMethodService.submissionInProgress = false;
        if (result) {
            this._enrollSession.submitApplicationData = result;
            sessionStorage.setItem('submitApplicationData', JSON.stringify(this._enrollSession.submitApplicationData));
            this._enrollSession.data.RetailEnroll.orderDetails = result;
            if (result.OrderID > 0) {
                // JS Tracking
                this._commonService.placeOrderTracking("", result, result.CustomerID);
                this._commonService.createCustomerTracking(result.CustomerID);
                this.router.navigate(['/Confirmation']);
            } else {
                this._toastr.error(result.Result.errorsField, 'Error');
                if (result.Result.errorsField[0] && result.Result.errorsField[0].includes("The operation has timed out")) {
                    this.dialogRef = this._matDialog.open(OrderAlertMessageComponent, {
                        width: '550px',
                        maxWidth: '550px',
                        height: '456px',
                        panelClass: '',
                        data: {
                          
                        },
                        disableClose: true
                    });
                    this.dialogRef.afterClosed()
                        .subscribe(response => {
                            if (response) {
                            }
                            this.router.navigate(['/', this.routPath]);
                        });
                }
            }
        }
        else {
            this._toastr.error('Something went wrong please try again', 'Error');
        }
    }, (err) => {
        this._sharedMethodService.submissionInProgress = false;
        this._toastr.error('Something went wrong please try again', 'Error');
    }, () => {
    });
    }
}
  getItemPrice(product) {

    var item = _.filter(this._enrollSession.data.RetailOrderProductDetail, (item) => {
      return (item.ParentItemCode == product.ItemCode || !item.ParentItemCode) && (item.ItemCode == product.ItemCode || item.ItemCode == (product.ItemCode + '-P65'));
    });
    return item.length > 0 ? item[0].PriceEach : 0;
  }
  ClickToRedirect(type){
    if(type === 'shippingDetails' || type === 'shippingMethod'){
      this.router.navigate(['/Shipping']);
    }
    else {
      this.router.navigate(['/Checkout']);
    }
  }
  AuthorizeChecked(){
    if(this.isAuthorize === false){
      this.isAuthorize = true;
    }
    else{
      this.isAuthorize = false;
    }
  }
  checkAutoshipDetails(): Promise<any>
    {
        const promise = new Promise((resolve, reject) => {
            this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
            this._restService.GetAutoOrders(this.LoggedInUser.CustomerID).subscribe((res: any) => {
                if (res.AutoOrderList.length > 0 && this.siteType != 'ultlifestyle') {
                    localStorage.setItem("isExistingAutoship", "true");
                    this.CustomAutoOrderList = [];
                    res.AutoOrderList.forEach(item => {
                        if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                            this.CustomAutoOrderList.push(item);
                        }
                    });
                    let minAutoOrderId = Math.min(...this.CustomAutoOrderList.map(e => e.AutoOrderID));
                    this.CustomAutoOrderList = _.filter(this.CustomAutoOrderList, (item) => {
                        return (item.AutoOrderID == minAutoOrderId);
                    });
                    localStorage.setItem('AutoOrderID', this.CustomAutoOrderList[0].AutoOrderID);
                }
                resolve(1);
            },
            (error)=> {
              this._toastr.error(error?.error?.Message,'error');
              alert("Your session has been expired. Please logout and login again.");
              this.Sessionlogout();
            });
        });
        return promise;

    }
    Sessionlogout() {
      this.cookieService.delete('access_token', '/');
      this.cookieService.delete('X-Auth', '/');
      this.cookieService.deleteAll('/');
      this._enrollSession.data.RetailOrderSummary = {};
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this._enrollSession.data.IsLoggedIn = false;
      this._enrollSession.data.LoggedInUserData = {};
      this._enrollSession.data.priceType = 0;
      sessionStorage.removeItem('this.DropdownMenu');
      this._auth.clearLoggedInData();
      sessionStorage.setItem('onUltLifestyleFlow', 'false');
      this.router.navigate(['/homepage']).then(() => {
          if (window.localStorage && window.sessionStorage) {
              window.localStorage.removeItem('user');
              window.localStorage.clear();
              window.sessionStorage.clear();
          }
          window.location.reload();
      });
      localStorage.setItem("isExistingAutoship", "false");
  }
  validateAllFormFields(formControl: FormControl) {
    const control: any = formControl;
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else {
      this.validateAllFormFields(control);
    }
}

  ngOnDestroy(): void {

  }
}
