import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { AuthService } from 'app/shared/auth.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import * as _ from 'underscore';
import { HttpClient } from '@angular/common/http';
import { SubscriptionTextPopupDialogComponent } from './subscriptionTextBox-dialog/subscriptionTextpopup-popup-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { API_URL, IMAGE_URL, objectToParams } from 'app/constants';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UserDialogComponent } from 'app/shared/user-dialog/user-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { MatDrawerMode } from '@angular/material/sidenav';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
  
@Component({
  selector: 'app-transformation',
  templateUrl: './transformation.component.html',
  styleUrls: ['./transformation.component.scss']
})
export class TransformationComponent implements OnInit, AfterViewInit {
  IMAGE_URL = IMAGE_URL;
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;
  @ViewChild('slickModalBanner', { static: false }) slickModalBanner: SlickCarouselComponent;
  @ViewChild('slickModal1', { static: false }) slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2', { static: false }) slickModal2: SlickCarouselComponent;
  @ViewChild('slickModal3', { static: false }) slickModal3: SlickCarouselComponent;
  public slideConfig = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "centerMode": false,
    // "centerPadding": '20%',
    "adaptiveHeight": false,
    "autoplay": false,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false,
    "responsive": [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          // centerPadding: '10%',
        }
      }
    ]
  };
  public slideConfig1 = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 3,
    "adaptiveHeight": false,
    "autoplay": false,
    "slidesToScroll": 3,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false
  };
  public slideConfig2 = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 1,
    "adaptiveHeight": true,
    "autoplay": false,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false
  };
  public slideConfig3 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }; 
  public slideConfig4 = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 1,
    "adaptiveHeight": true,
    "autoplay": false,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false
  };
  purium_reviews = [];
  meetCoreProducts = [];
  productBenefitFrames = [];
  dialogRef: any;
  webalias: string;
  LoggedInUser:any = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
  sharelinkURL:any;
  bannerstext: any = [];
  bgColor:any;
  activeSection: string = ''; 
  ULTLifestyleProducts: any;
  ULTDarkBerryProducts: any;
  ultImage: any;
  ultDarkImage: any;
  ultCustomizableImage: any;
  ultLongDetail: any;
  ultLongDetail3: any;
  ultLongDetail4: any;
  reviews: any;
  reviewMediaFile: any = [];
  reviewList: any;
  anchors: any;
  customerReview: any;
  customerReviewImage: any;
  customerReviewVideo: any;
  ratingCount = 5;
  rating = 1;
  addImage: boolean = false;
  addVideo: boolean = false;
  reviewSection: boolean = false;
  @ViewChild('ReviewElement', { static: false }) ReviewElement: ElementRef;
  customerReviewForm: FormGroup;
  itemJudgmeRes: any;
  YourPrice: any;
  RetailPrices: any;
  YourCustomizablePrice:any;
  RetailCustomizablePrices:any;
  YourDarkPrice:any;
  RetailDarkPrices:any;
  siteFlow: any;
  orderHistoryUrl:any;
  subscriptionURL: any;
  settingsURL:any;
  BPBackOfficeUrl:any;
  learnMoreRewardPoints:any;
  userData: any;
  SubscriptionProcessType: any;
  isshowmenu: boolean = false;
  isshowreward: boolean = false;
  isshowAccount:boolean = false;
  public SideAccountBar: boolean = false;
  mode = new FormControl('over' as MatDrawerMode);
  location: Location;
  @ViewChild('content') content!: ElementRef;
  @ViewChild('thumb') thumb!: ElementRef;
  @ViewChild('customScrollbar') customScrollbar!: ElementRef;
  @ViewChild('content2') content2!: ElementRef;
  @ViewChild('thumb2') thumb2!: ElementRef;
  @ViewChild('customScrollbar2') customScrollbar2!: ElementRef;
  @ViewChild('content3') content3!: ElementRef;
  @ViewChild('thumb3') thumb3!: ElementRef;
  @ViewChild('customScrollbar3') customScrollbar3!: ElementRef;
  isDragging = false;
  startY = 0;
  scrollTop = 0;
  ItemCode: any;
  constructor(
    private _restService: RestService,
    private _http: HttpClient,
      private _toastr: ToastrService,
      private router: Router,
      private _renderer2: Renderer2,
      public _auth: AuthService,
      public _sharedMethodService: SharedMethodService, 
      public _enrollSession: EnrollSessionService,
      private _matDialog: MatDialog,
      private _formBuilder: FormBuilder,
      public cookieService: CookieService,
      private _location: Location,
      private _activateRoute: ActivatedRoute,
  ) { 
    var url = new URL(window.location.href);
    this.webalias = this._activateRoute.snapshot.queryParamMap?.get('giftcard') || url?.searchParams.get("giftcard") || sessionStorage.getItem('giftCardName');
    this.sharelinkURL = sessionStorage.getItem('FromShareLinkCart');
    sessionStorage.setItem('siteType', 'ultlifestyle');
    this.location = this._location;
    this.siteFlow = sessionStorage.getItem('siteType');
    this._activateRoute.queryParams.subscribe(params => {
      this.ItemCode = params['itemCode'];
    });
    if(this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary?.Enrollpack !== undefined && this._enrollSession.data.RetailOrderSummary?.Enrollpack !== null && this._enrollSession.data.RetailOrderSummary.Enrollpack?.length > 0 && (this.sharelinkURL === undefined || this.sharelinkURL === null || this.sharelinkURL==="false")){
        this._enrollSession.data.RetailOrderSummary.InitialOrders = [];
        this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
        this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
        this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
        this._sharedMethodService.isOpen = false;
    }
    this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || {};
    this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
    if (this._enrollSession.data && this._enrollSession.editTransformationPack != 'true' && Object.keys(this._enrollSession.data).length === 0 && !sessionStorage.getItem("collection")) {
        this._enrollSession.data.IsShowCart = false;
        this._enrollSession.data.page = this.location.path();
        this._enrollSession.data.TotalItemLength = 0;
        this._enrollSession.data.RetailEnroll = {};
        this._enrollSession.data.InitOrder = [];
        this._enrollSession.data.InitialOrdersSubTotal = 0;
        this._enrollSession.data.RetailOrderSummary = {};
        this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
        this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
        this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
        this._enrollSession.data.EligibleForFreeUpgrade = false;
        this._enrollSession.data.RetailEnroll.ShippingMethod = '';
        this._enrollSession.data.usePrimaryCard = null;
        this._enrollSession.data.primaryCreditCardField = {};
        this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
        this._enrollSession.data.IsUpGrade =
            this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
        if (this._enrollSession.data.IsUpGrade) {
            localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
        }
        this._enrollSession.data.Volume109 = 0;
        this._enrollSession.data.SmartOrderBVTotal = 0;
        this._enrollSession.data.InitialOrdersQVTotal = 0;
        this._enrollSession.data.AddOnOrdersQVTotal = 0;
    }
  }

  ngOnInit(): void {
    this.setDynamicBackground();
    this.BeforeAfterScriptLoaded();
    this.getULTProduct(504).then(() => this.getULTProduct(655)).then(() => this.AddToCartPacks(this.ItemCode));
    this.purium_reviews = [
      { image: '../../../assets/images/Transformation/review-image0.png', review: {review_heading:'ULT transformed my life',review_text:'In June, I started the ULT because I had suffered from gut issues for 11 years. I also had mild toxicity and a gluten sensitivity. Within the first week of taking the supplements on the ULT program, my skin rash cleared! By the end of the first 30 days, I released 10 pounds of inflammation and am now able to eat gluten again! I no longer have intense pain in my intestines regularly and am not bloated after eating every meal. This program is a game-changer for me! I feel better than I have in years! I highly recommend this program to anyone and everyone!',name:'Jacqueline'} },
      { image: '../../../assets/images/Transformation/review-image1.png', review: {review_heading:'Lost 100 lbs and counting',review_text:'No more chronic inflammation. No more chronic pain. No more hormonal imbalances. No more acne. No more rosacea. No more anxiety. No more depression. No more fatty liver disease. No more uterine fibroids the size of a softball (there were 3 of them in there). Lost 100 lbs and counting. More energy. More joy. The list goes on... I now get to enjoy an active lifestyle with a perfect bill of health. No weight loss surgery. No fad diets. All lifestyle transformation and lots of pure organic superfoods.',name:'Chantel'} },
      { image: '../../../assets/images/Transformation/review-image2.png', review: {review_heading:'lost 25 lbs!',review_text:"she lost over 25lbs!! She is a busy young lady with a very demanding career in charge of many roles at work. To take charge of your health is the key for success in life, it gives you the drive to do your best in all! So what did she do? She took charge of her health! That's where I started her on a reset! Organic superfoods, no fillers, no additives, etc. 3 months later less inflammation, less bloating, more energy, lost 25 lbs!",name:'Jaz'} },
      { image: '../../../assets/images/Transformation/review-image3.png', review: {review_heading:"I can't imagine my life any other way now!!",review_text:"After years of yo-yo diets, bodybuilding, and then two back-to-back babies, my body was screaming for help that I didn't know how to provide. Being diagnosed with hypothyroidism sent me on a quest to find a natural way to correct my imbalances and inflammation. I completed my 90-day ULT, and I'm onto forever. I actually only lost 3 lbs. over 90 days, but my mind, body, and soul changed, and that outweighs any number on a scale.",name:'Gerrica'} },
      { image: '../../../assets/images/Transformation/review-image4.png', review: {review_heading:"Today I feel better than I did when I was in my early 20s!",review_text:"50 lbs overweight, sick, and nearly dead. That's where I was over 10 years ago. Today I feel better than I did when I was in my early 20s! That is wild to me because I was told I was never going to work out again and needed a wheelchair and someone to take care of me. How deflating... My intention is to show people there is a better way where you can take back control of your health and I can show you the way. It's not an easy path but it's definitely worth it",name:'Kevin'} },
      { image: '../../../assets/images/Transformation/review-image5.png', review: {review_heading:"RENEW YOUR SKIN",review_text:"This before and after photo of myself reminds me how far I've come in my health journey. I am a mother of 2 small children and I couldn't keep up with high energy boys. Since incorporating Purium into my lifestyle I noticed a changes in my",name:'Naomi'} },
      { image: '../../../assets/images/Transformation/review-image6.png', review: {review_heading:"I love",review_text:"Before using Purium, I was always tired, struggled with IBS, and very unhappy with my overall health. I knew I needed a change, so I took the chance on something new. I'm over 90 days in now and I couldn't be happier! My IBS has changed and I'm finally",name:'Dayna'} }
    ];
    this.meetCoreProducts = [
      { image: IMAGE_URL+'/images/Transformation/PowerShake.png' },
      { image: IMAGE_URL+'/images/Transformation/BiomeMedic.png' },
      { image: IMAGE_URL+'/images/Transformation/SuperAmino.png' },
      { image: IMAGE_URL+'/images/Transformation/ApotheCherry.png' },
      { image: IMAGE_URL+'/images/Transformation/SuperCleansR.png' },
    ];
    this.productBenefitFrames = [
      { image: '../../../assets/images/Transformation/product-benefits1.png', heading: '../../../assets/images/Transformation/month-1.svg' },
      { image: '../../../assets/images/Transformation/product-benefits2.png', heading: '../../../assets/images/Transformation/month-2.svg' },
      { image: '../../../assets/images/Transformation/product-benefits3.png', heading: '../../../assets/images/Transformation/month-3.svg' },
      { image: '../../../assets/images/Transformation/product-benefits4.png', heading: '../../../assets/images/Transformation/month-4.svg' },
    ]
    if (this._auth.isLoggedIn()) {
      if (this._enrollSession.data.LoggedInUserData?.CustomerType==20 || this._enrollSession.data.LoggedInUserData?.CustomerType==22 ) {
        this.getImage(this._enrollSession.data.LoggedInUserData?.EnrollerID);
      } else {
        this.getImage(this._enrollSession.data.LoggedInUserData?.CustomerID);
      }
      this.getEnrollerDetails();
    } else {
      this.giftcardFunctionality();
    }
    this.GetIshopBannerContent();
    this.customerReviewForm = this.createCustomerReviewForm();
  }

  setDynamicBackground(): void {
    const imageUrl1 = `${IMAGE_URL}/images/Transformation/whiteWashBG.png`;
    const imageUrl2 = `${IMAGE_URL}/images/Transformation/whiteWashwithRect.png`;
    const imageUrl3 = `${IMAGE_URL}/images/Transformation/whiteWashwithRectMob.png`;
    const imageUrl4 = `${IMAGE_URL}/images/Transformation/line-with-arrow.png`;
    const imageUrl5 = `${IMAGE_URL}/images/Transformation/rectangle-ult.png`;
  
    document.documentElement.style.setProperty('--image-bg-url1', `url(${imageUrl1})`);
    document.documentElement.style.setProperty('--image-bg-url2', `url(${imageUrl2})`);
    document.documentElement.style.setProperty('--image-bg-url3', `url(${imageUrl3})`);
    document.documentElement.style.setProperty('--image-bg-url4', `url(${imageUrl4})`);
    document.documentElement.style.setProperty('--image-bg-url5', `url(${imageUrl5})`);
  }

  AddToCartPacks(itemCode){
    if(itemCode == '11200'){
      this.ULTLifestyleProducts.forEach(item => {
        if(item.ItemCode == '11240'){
          this._sharedMethodService.ContinueRetailOrder(item, 1, 2);
        }
        if (item.ItemCode == "11250" || item.ItemCode == "11260") {
            this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11250" ? "2Month" : "4Month"));
            this.router.navigateByUrl('/Add-ons');
        }
      });
    }else if(itemCode == '11286'){
      this.ULTDarkBerryProducts.forEach(item => {
        if(item.ItemCode == '11280'){
          this._sharedMethodService.ContinueRetailOrder(item, 1, 2);
        }
        if (item.ItemCode === "11282"|| item.ItemCode === "11284") {
            this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11282" ? "2Month" : "4Month"));
            this.router.navigateByUrl('/Add-ons');
        }
    });
    }
    else if(itemCode == '11205'){
      let filterItems = this.ULTLifestyleProducts.filter(item => 
        ["11244", "11254", "11264"].includes(item.ItemCode)
      );
      let singleItem = this.ULTLifestyleProducts.find(item => item.ItemCode === '11244');
      if (singleItem && !filterItems.includes(singleItem)) {
        filterItems.push(singleItem);
      }
      const dialogRef = this._matDialog.open(OrderSubItemComponent, {
        width: '600px',
        panelClass: ['order-sub-item', 'sub-item-modal'],
        data: {
          item: filterItems,
          type: 2
        }
      });
      dialogRef.afterClosed().subscribe(results => {
        this.router.navigateByUrl('/Add-ons');
      });
    }
  }

  BeforeAfterScriptLoaded(){
    const script = this._renderer2.createElement('script');
    script.src = 'https://apps.elfsight.com/p/platform.js';
    script.defer = true;
    this._renderer2.appendChild(document.body, script);
  }

  ngAfterViewInit(): void {
    const checkWidgetLoaded = setInterval(() => {
      const leftArrow = document.querySelector('.eapp-before-and-after-slider-arrow-previous');
      const rightArrow = document.querySelector('.eapp-before-and-after-slider-arrow-next');
  
      if (leftArrow && rightArrow) {
        this.attachCustomNavigation();
        clearInterval(checkWidgetLoaded); // Stop the interval once loaded
      }
    }, 1000); // Check every second
  }
  onScroll(section: number) {
    const { contentEl, thumbEl, scrollbarEl } = this.getElementsBySection(section);
  
    if (contentEl && thumbEl && scrollbarEl) {
      const contentHeight = contentEl.scrollHeight;
      const containerHeight = contentEl.clientHeight;
      const scrollRatio = contentEl.scrollTop / (contentHeight - containerHeight);
  
      if(scrollRatio >= 0.98){
        thumbEl.style.top = `${(scrollRatio * 100) - 10}%`;
      }
      else{
        thumbEl.style.top = `${scrollRatio * 100}%`;
      }
  
      if (contentHeight <= containerHeight) {
        thumbEl.style.top = '0';
        thumbEl.style.height = '100%';
        scrollbarEl.style.setProperty('--scroll-thumb-top', `100%`);
        thumbEl.style.display = 'none'; // Hide thumb for non-scrollable content
      } else {
        thumbEl.style.display = 'block';
      }
  
      scrollbarEl.style.setProperty('--scroll-thumb-top', `${scrollRatio * 100}%`);
    }
  }
   // Start dragging the scrollbar thumb
   startDrag(event: MouseEvent | TouchEvent, section: number) {
    this.isDragging = true;
    const clientY = event instanceof TouchEvent ? event.touches[0].clientY : event.clientY;
    this.startY = clientY;

    const { contentEl } = this.getElementsBySection(section);
    if (contentEl) {
        this.scrollTop = contentEl.scrollTop;

        const moveHandler = (e: MouseEvent | TouchEvent) => this.onDrag(e, section);
        const stopHandler = () => this.stopDrag(section);

        document.addEventListener('mousemove', moveHandler);
        document.addEventListener('mouseup', stopHandler, { once: true });
        document.addEventListener('touchmove', moveHandler, { passive: false });
        document.addEventListener('touchend', stopHandler, { once: true });
    }
}


  // Drag the scrollbar thumb
  onDrag(event: MouseEvent | TouchEvent, section: number) {
    if (!this.isDragging) return;

    event.preventDefault();

    const clientY = event instanceof TouchEvent ? event.touches[0].clientY : event.clientY;
    const { contentEl } = this.getElementsBySection(section);

    if (contentEl) {
        const deltaY = clientY - this.startY;
        const scrollRatio = contentEl.scrollHeight / contentEl.clientHeight;

        contentEl.scrollTop = this.scrollTop + deltaY * scrollRatio;
        this.updateThumbPosition(section);
    }
  }


  // Stop dragging the scrollbar thumb
  stopDrag(section: number) {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.onDrag as any);
    document.removeEventListener('touchmove', this.onDrag as any);
  }

  scrollTo(event: MouseEvent, section: number) {
    const { contentEl } = this.getElementsBySection(section);
    if (!contentEl) return;
  
    const scrollbarEl = event.currentTarget as HTMLElement;
    const scrollbarHeight = scrollbarEl.clientHeight;
    const clickY = event.clientY - scrollbarEl.getBoundingClientRect().top;
    const scrollRatio = clickY / scrollbarHeight;
  
    const contentHeight = contentEl.scrollHeight;
    const containerHeight = contentEl.clientHeight;
  
    const newScrollTop = scrollRatio * (contentHeight - containerHeight);
    contentEl.scrollTop = newScrollTop;
  
    this.updateThumbPosition(section);
  }

  updateThumbPosition(section: number) {
    const { contentEl, thumbEl } = this.getElementsBySection(section);
  
    if (contentEl && thumbEl) {
      const contentHeight = contentEl.scrollHeight;
      const containerHeight = contentEl.clientHeight;
      const scrollRatio = contentEl.scrollTop / (contentHeight - containerHeight);

      if(scrollRatio >= 0.98){
        thumbEl.style.top = `${(scrollRatio * 100) - 10}%`;
      }
      else{
        thumbEl.style.top = `${scrollRatio * 100}%`;
      }
    }
  }
  private getElementsBySection(section: number) {
    let contentEl: HTMLElement, thumbEl: HTMLElement, scrollbarEl: HTMLElement;
  
    switch (section) {
      case 1:
        contentEl = this.content.nativeElement;
        thumbEl = this.thumb.nativeElement;
        scrollbarEl = this.customScrollbar.nativeElement;
        break;
      case 2:
        contentEl = this.content2.nativeElement;
        thumbEl = this.thumb2.nativeElement;
        scrollbarEl = this.customScrollbar2.nativeElement;
        break;
      case 3:
        contentEl = this.content3.nativeElement;
        thumbEl = this.thumb3.nativeElement;
        scrollbarEl = this.customScrollbar3.nativeElement;
        break;
    }
    return { contentEl, thumbEl, scrollbarEl };
  }


  attachCustomNavigation(): void {
    const observer = new MutationObserver((mutations, obs) => {
  
      const leftArrow = document.querySelector('.eapp-before-and-after-slider-arrow-previous');
      const rightArrow = document.querySelector('.eapp-before-and-after-slider-arrow-next');
  
      if (leftArrow && rightArrow) {
  
        (document.querySelector('.custom-left-arrow') as HTMLElement)?.addEventListener('click', () => {
          (leftArrow as HTMLElement).click();
        });
  
        (document.querySelector('.custom-right-arrow') as HTMLElement)?.addEventListener('click', () => {
          (rightArrow as HTMLElement).click();
        });
  
        obs.disconnect(); // Stop observing once buttons are found
      }
    });
  
    const targetNode = document.querySelector('.elfsight-app-c3a4d58e-87d7-4dcf-b1ed-53892c5a3987') || document.body;
  
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    } else {
    }
  }
  

  GetIshopBannerContent() {
    const self = this;
    self._restService.GetIshopBannerContent().subscribe(
        (res: any) => {
            if (res?.Status === "Success") {
                let data = res.Data.BannerContent;
                this.bgColor = data?.backgroundColor?.split(";");
                this.bannerstext = [
                    { text: data.bannerText1 || 'Celebrating 20 Years of Direct Scales of Pure + Premium Superfoods', url: data.bannerUrl1 || '',bgcolor: this.bgColor?.length > 0 ? (this.bgColor[0]): '#f99f27' },
                    { text: data.bannerText2 || 'We are experiencing delivery delays due to severe weather conditions.', url: data.bannerUrl2 || '',bgcolor: this.bgColor?.length > 1 ? this.bgColor[1]: '#C688B9' },
                    { text: data.bannerText3 || 'Try our New Mushroom Products today!', url: data.bannerUrl3 || 'https://ishoppurium.com/Collection5',bgcolor: this.bgColor?.length > 2 ? this.bgColor[2]: '#566A26 ' }
                ];
                localStorage.setItem("bannerColorTextURL",JSON.stringify(this.bannerstext));
            }
            else{
                this.router.navigate(['/homepage']);
            }
        },
        error => {
            console.log(error , 'in error');
            this.router.navigate(['/homepage']);
        });
  }
  nextBanner() {
    this.slickModalBanner.slickNext();
  }
  prevBanner() {
    this.slickModalBanner.slickPrev();
  }
  nextCore() {
    this.slickModal1.slickNext();
  }
  prevCore() {
    this.slickModal1.slickPrev();
  }

  BuyNowToday(itemcode) {
    sessionStorage.setItem('siteType', 'ultlifestyle');
    if(itemcode === "112"){
      this.ULTLifestyleProducts = localStorage.getItem('EnrollmentULTLifestyleProducts') ? JSON.parse(localStorage.getItem('EnrollmentULTLifestyleProducts')) : this.ULTLifestyleProducts;
    // this.ULTDarkBerryProducts = localStorage.getItem('EnrollmentULTDarkBerryProducts') ? JSON.parse(localStorage.getItem('EnrollmentULTDarkBerryProducts')) : this.ULTDarkBerryProducts;
    let filterItems = [];
    const productCategories = {
      '11244': this.ULTLifestyleProducts,
      // '11240': this.ULTLifestyleProducts,
      // '11280': this.ULTDarkBerryProducts
    };
    if(itemcode == '11244'){
      let productList = productCategories[itemcode] || [];
      filterItems = productList.filter(item => 
        ["11244", "11254", "11264"].includes(item.ItemCode)
      );
      let singleItem = productList.find(item => item.ItemCode === itemcode);
      if (singleItem && !filterItems.includes(singleItem)) {
        filterItems.push(singleItem);
      }
      this.todaysOrderSubscriptionPoup(filterItems, 1, 2, itemcode);
    }
    }
    else{
      var queryString = window.localStorage.getItem('SSOToken') ? ("&SSOToken=" + encodeURIComponent(window.localStorage.getItem('SSOToken'))+(this.webalias? "&giftcard="+this.webalias : '')+"&site=ultlifestyle&itemcode="+ itemcode) : (this.webalias? "?site=ultlifestyle&itemcode="+ itemcode+"&giftcard="+this.webalias : "?site=ultlifestyle&itemcode="+ itemcode) ;  
      this.router.navigateByUrl('/OrderReview'+queryString);
    }
  }

  todaysOrderSubscriptionPoup(itemCode, quantity?: any, type?: any, itemID?:any) {
    sessionStorage.setItem('siteType', 'ultlifestyle');
        if (itemCode && itemCode.length > 0 && itemCode[0].KitMembers && itemCode[0].KitMembers.length > 0) {
          const dialogRef = this._matDialog.open(OrderSubItemComponent, {
            width: '600px',
            panelClass: ['order-sub-item', 'sub-item-modal'],
            data: {
              item: itemCode,
              type: type
            }
          });
          dialogRef.afterClosed().subscribe(results => {
            this.router.navigateByUrl('/Add-ons');
          });
        } else {
          if (itemCode.ItemCode === "11240") {
            if(itemCode.ItemCode === "11240"){
              this._sharedMethodService.ContinueRetailOrder(itemCode, quantity, type);
            }
            this.ULTLifestyleProducts.forEach(item => {
                if (item.ItemCode == "11250" || item.ItemCode == "11260") {
                    this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11250" ? "2Month" : "4Month"));
                    this.router.navigateByUrl('/Add-ons');
                }
            });
          }
          else if(itemCode.ItemCode === "11280"){
            if(itemCode.ItemCode === "11280"){
              this._sharedMethodService.ContinueRetailOrder(itemCode, quantity, type);
            }
            this.ULTDarkBerryProducts.forEach(item => {
              if (item.ItemCode === "11282"|| item.ItemCode === "11284") {
                  this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11282" ? "2Month" : "4Month"));
                  this.router.navigateByUrl('/Add-ons');
              }
          });
          }
        }
  }

  SubscriptionTextPopup(){
    this.dialogRef = this._matDialog.open(SubscriptionTextPopupDialogComponent, {
        width: '80%',
        maxWidth: '80%',
        height: '250px',
        disableClose: true,
    });
    this.dialogRef.afterClosed()
        .subscribe(response => {
  
        });
  }
  giftcardFunctionality() {
    if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
      const request = '?WebAlias=' + this.webalias;
      this._restService.GetCustomerSite(request).subscribe((result: any) => {
        if (result.Data != null) {
          this._enrollSession.data.webAlias = this.webalias;
          sessionStorage.setItem('giftCardName', this._enrollSession.data.webAlias);
          this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
          this.getImage(result.Data.customerIDField);
        } else {
          this._toastr.error('Invalid WebAlias.', 'Error');
        }
      }, error => {
        this._toastr.error('Somthing is wrong Please try again', 'Error');
      });
    }
  }
  getImage(id) {
    this._enrollSession.customerImage =  API_URL + "/NonProfitEnrollment/GetCustomerImageData?customerId=" + id || IMAGE_URL+"/images/avatars/profile_icon.png";
  }
  isBPCustomerExist(){
    let customerType = [4, 5, 6, 7, 8, 10, 17, 19, 21, 24,25];
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let customerFound = customerType.find((element) => element === this.LoggedInUser.CustomerType);
    return customerFound ? true : false;
  }
  getEnrollerDetails()
  {
      let enrollerInfo =  JSON.parse(localStorage.getItem('EnrollerData'));
      let enrollername = enrollerInfo?.EnrollerFirstName + " " + enrollerInfo?.EnrollerLastName;
      return enrollername;
  }
  
  toggleSection(section: string): void {
    this.activeSection = section;
  }

  getULTProduct(webCategoryId): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = {
        'EnrollmentType': '',
        'CustomerType': 22,
        'WebCategoryId': webCategoryId,
        'isUpgradeCustomer': false
      };
  
      this._restService.getProducts(request).subscribe((res: any) => {
        switch (webCategoryId) {
          case 504:
            this.ULTLifestyleProducts = res.Items;
            this.GetAllJudgMeProducts();
            this.productImage('11240');
            this.productsPrices('11240');
            this.productsPrices('11244');
            this.LongDetail('11240');
            this.toggleSection('productBenefits');
            localStorage.setItem('EnrollmentULTLifestyleProducts', JSON.stringify(this.ULTLifestyleProducts));
            break;
          case 655:
            this.ULTDarkBerryProducts = res.Items;
            this.productImage('11280');
            this.productsPrices('11280');
            localStorage.setItem('EnrollmentULTDarkBerryProducts', JSON.stringify(this.ULTDarkBerryProducts));
            break;
        }
        resolve();
      }, (error) => {
        reject(error);
      });
    });
  }
  productImage(itemCode?) {
    const productCategories = {
      '11240': this.ULTLifestyleProducts,
      '11280': this.ULTDarkBerryProducts,
    };
    const productList = productCategories[itemCode];
    if (productList) {
      if(itemCode == '11240'){
        this.ultImage = productList.find(item => item.ItemCode === itemCode)?.SmallPicture;
      }
      if(itemCode == '11240'){
        this.ultCustomizableImage = productList.find(item => item.ItemCode === itemCode)?.SmallPicture;
      }
      if(itemCode == '11280'){
        this.ultDarkImage = productList.find(item => item.ItemCode === itemCode)?.SmallPicture;
      }
    }
  }
  productsPrices(itemCode) {
    const productCategories = {
      '11240': this.ULTLifestyleProducts,
      '11244': this.ULTLifestyleProducts,
      '11280': this.ULTDarkBerryProducts
    };
  
    const productList = productCategories[itemCode];
    if (productList) {
      const product = productList.find(item => item.ItemCode === itemCode);
      if (product) {
        switch(itemCode) {
          case '11240':
            this.YourPrice = product.Price;
            this.RetailPrices = product.Other1Price;
            break;
          case '11244':
            this.YourCustomizablePrice = product.Price;
            this.RetailCustomizablePrices = product.Other1Price;
            break;
          case '11280':
            this.YourDarkPrice = product.Price;
            this.RetailDarkPrices = product.Other1Price;
            break;
        }
      }
    }
  }
  LongDetail(itemCode) {
    const productCategories = {
      '11240': this.ULTLifestyleProducts,
    };
  
    const productList = productCategories[itemCode];
    if (productList) {
      const product = productList.find(item => item.ItemCode === itemCode);
      if (product) {
        this.ultLongDetail = product.LongDetail;
        this.ultLongDetail3 = product.LongDetail3;
        this.ultLongDetail4 = product.LongDetail4;
      }
    }
  }
  GetAllJudgMeProducts() {
    let itemCode = ['11200', '11240', '11280'];
      this._restService.GetAllJudgMeProducts().subscribe((items: any) => {
          items.forEach((item) => {
              if ((this.ULTLifestyleProducts || this.ULTDarkBerryProducts) && itemCode.includes(item.ItemCode)) {
                  this.getReviews(item.Handle, 1);
                  this.handleReview(item.Handle);
                  this.itemJudgmeRes = item;
              }
          });
      });
      
  }
  handleReview(handle) {
    this.getReviews(handle, 1);
  }
  waitForElement(classname, callback) {
      var poops = setInterval(function () {
          if (document.getElementsByClassName(classname)) {
              clearInterval(poops);
              callback();
          }
      }, 1000);
  }
  createCustomerReviewForm() {
      return this._formBuilder.group({
          Name: ['', Validators.required],
          Email: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]],
          ReviewTitle: ['', Validators.required],
          WriteYourComment: ['', Validators.required],
          reviewPicture: [''],
          reviewVideo: ['']
      })
  }
  getReviews(product, page) {
      let a = product.split(' ');
      let b = a.join('-');
      this._restService.JudgeMeReviews(b, page).subscribe((res: any) => {
          let c = res.widget.split("data-number-of-reviews='");
          let d = c[1].split("'");
          this.reviews = d[0];
          let responseHtml = res?.widget;
          responseHtml = responseHtml.replaceAll("data-src", "src")

          if (responseHtml) {
              this.reviewList = this.convertSpecialCharacters(responseHtml);
              var _self = this;
              // this.waitForElement('jdgm-write-rev-link', function () { _self.writeCustomerReview() });
              // this.waitForElement('jdgm-rev__timestamp', function () { _self.addTimestamp() });
          }

      })
  }
  convertSpecialCharacters(text) {
      return text.replace(/â€™/g, "'")
                .replace(/â€˜/g, "'")
                .replace(/â€œ/g, '"')
                .replace(/â€/g, '"')
                .replace(/â€¦/g, '...')
                .replace(/â€“/g, '-')
                .replace(/â€¢/g, '*')
                .replace(/â€/g, '•')
                .replace(/â€º/g, '›')
                .replace(/â€º/g, '‹')
                .replace(/ðŸ™/g, '🙏')
                .replace(/ðŸ¥°/g, '😰')
                .replace(/ðŸ¦‹/g, '🦋')
                .replace(/ðŸ¿/g, '🏿')
                .replace(/ðŸŒ¿/g,'🌿')
                .replace(/ðŸ™Œ/g,'🙌')
                .replace(/ðŸ¼/g,' 🏽')
                .replace(/ðŸ’£/g,'💣')
                .replace(/â¤ï¸/g,'❤️')
                .replace(/ðŸ¼/g,'👍🏻')
                .replace(/â™¥ï¸/g,'♥️')
                .replace(/ðŸ’š/g,'💖')
                .replace(/ðŸ¶/g,'🐶')
                .replace(/ðŸ’™/g,'💙')
                .replace(/ðŸ•/g,'🐕')
                .replace(/ðŸ’¯/g,'💯');
  }
  
  login() {
    const dialogRef = this._matDialog.open(UserDialogComponent, {
      width: '500px',
      maxWidth: '500px',
      height: '356px',
      panelClass: '',
      disableClose: true,
      data: {
        header: true,
      },
      autoFocus:false
    });
    this._sharedMethodService.isOpenDialog = true;
    dialogRef.afterClosed().subscribe(result => {

      this._sharedMethodService.isOpenDialog = false;
      if (result) {
        this.userData = JSON.parse(this._auth.getUserData());
        this._restService.GetAutoOrders(this.userData.CustomerID).subscribe((res: any) => {
          if (res.AutoOrderList.length > 0 && this.siteFlow != 'ultlifestyle') {
              localStorage.setItem("isExistingAutoship", "true");
            this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || {}
            let FromShareLinkCart = sessionStorage.getItem("FromShareLinkCart");
            this._enrollSession.data.RetailOrderSummary.InitialOrders =  FromShareLinkCart == "true" ? this._enrollSession.data.RetailOrderSummary.InitialOrders  : [];
            let CustomAutoOrderList = [];
            res.AutoOrderList.forEach(item => {
              if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                CustomAutoOrderList.push(item);
              }
            });
            var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
            CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
              return (item.AutoOrderID == minAutoOrderId);
            });
              localStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
              localStorage.setItem("AutoOrderDetails", JSON.stringify(CustomAutoOrderList[0]));
              let storedProcessType = localStorage.getItem('SubscriptionProcessType') ? JSON.parse(localStorage.getItem('SubscriptionProcessType')) : '';
              this.SubscriptionProcessType = 
              CustomAutoOrderList[0].ProcessType == 0
                  ? "AutoOrderProcessTy1"
                  : (storedProcessType || "AutoOrderProcessTy2");
            localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
            this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
          }
        }, error => {
          console.log("In error")
        })
      }
    });
  }
  logout() {
    this.cookieService.delete('access_token', '/');
    this.cookieService.delete('X-Auth', '/');
    this.cookieService.deleteAll('/');
    this._enrollSession.data.RetailOrderSummary = {};
    this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
    this._enrollSession.data.IsLoggedIn = false;
    this._enrollSession.data.LoggedInUserData = {};
    this._enrollSession.data.priceType = 0;
sessionStorage.removeItem('this.DropdownMenu');
    this._auth.clearLoggedInData();
    sessionStorage.setItem('onUltLifestyleFlow', 'false');
    this.router.navigate(['/Home']).then(() => {
        if (window.localStorage && window.sessionStorage) {
            window.localStorage.removeItem('user');
            window.localStorage.clear();
            window.sessionStorage.clear();
        }
        window.location.reload();
    });
    localStorage.setItem("isExistingAutoship", "false");
  }

  isUpgradableCustomer(){
    let customerType = [20,22];
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let customerFound = customerType.find((element) => element === this.LoggedInUser.CustomerType);
    return customerFound ? true : false;
  }

  ClicktoUpgrade() {
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let currentUrl = window.location.href;
    let url = currentUrl.includes('ishoppurium') ? 'https://puriumenrollment.com/TodaysOrder?customerId=' : 'http://phpenrollnew.wsicloud.net/TodaysOrder?customerId='; 
    window.open(url+ this.LoggedInUser.CustomerID, '_blank');
  }

  ClicktoOrderHistory(){
    this.userData = JSON.parse(this._auth.getUserData());
    let customerId = this._enrollSession.submitApplicationData.CustomerID || this.userData.CustomerID;
    let windowref = window.open();
    this._restService.SilentLogin(customerId).subscribe((res: any) => {
      this.orderHistoryUrl = res;
      windowref.location = this.orderHistoryUrl, "_blank";
    }, error => {
      console.log("In error")
    });
  }

  ClicktoSubscription() {
    let ssoToken = sessionStorage.getItem('SSOToken');
    if (ssoToken && ssoToken != undefined && ssoToken != null) {
      this.manageSubscriptionAutoship(ssoToken);
    } else {
      this.getSSOToken();
    }
  }
  getSSOToken()
  {
    this.LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    let ssoRequest = 
    objectToParams({
      client_id: this.LoggedInUser.CustomerID,
      grant_type:'password',
      userName: this.LoggedInUser.Email
    });
    this._restService.Token(ssoRequest).subscribe((res: any) => {
      if (res.SSOToken) {
        this.manageSubscriptionAutoship(res.SSOToken);
      }else{
        alert("Your session has been expired. Please logout and login again.");
      }
    });
  }
  manageSubscriptionAutoship(ssoToken) {
    let windowref = window.open();
    this.subscriptionURL = 'https://monthlysubsription.puriumenrollment.com/login?SSOToken=' + ssoToken;
    windowref.location = this.subscriptionURL, "_blank";
  }
  ClicktoBPBackOffice(){
    let url:any = 'https://backoffice.mypurium.com/orders#';
    let urlBPOffice:any = 'https://backoffice.mypurium.com/';
    let windowref = window.open();
    this.userData = JSON.parse(this._auth.getUserData());
    this._restService.SilentLogin(this.userData.CustomerID).subscribe((res: any) => {
      this.BPBackOfficeUrl = res;
      windowref.location = this.BPBackOfficeUrl, "_blank";
      windowref.location = url;
      windowref.location = urlBPOffice;
    }, error => {
      console.log("In error")
    });
  }

  ClicktoSettings(){
    let url:any = 'https://backoffice.mypurium.com/account/settings';
    let windowref = window.open();
    this.userData = JSON.parse(this._auth.getUserData());
    this._restService.SilentLogin(this.userData.CustomerID).subscribe((res: any) => {
      this.settingsURL = res;
      windowref.location = this.settingsURL, "_blank";
      setTimeout(() => {
        windowref.location = url;
      }, 1000);
    }, error => {
      console.log("In error")
    });
  }

  ClickToLearnMoreRewardPoints(){
    let url:any = 'https://backoffice.mypurium.com/puriumrewards';
    let windowref = window.open();
    this.userData = JSON.parse(this._auth.getUserData());
    this._restService.SilentLogin(this.userData.CustomerID).subscribe((res: any) => {
      this.learnMoreRewardPoints = res;
      windowref.location = this.learnMoreRewardPoints, "_blank";
      setTimeout(() => {
        windowref.location = url;
      }, 1000);
    }, error => {
      console.log("In error")
    });
  }
  toggleAccountSideBar(){
    this.SideAccountBar = !this.SideAccountBar;
  }
  RedirectToHome(){
    this.router.navigate(['/']);
  }
}